const authPrefix = "auth";
const userPrefix = "user";
const versionPrefix = "version";
const vendorPrefix = "vendor";
const managementPrefix = "management";
const visitorPrefix = "visitor";
const awsPrefix = "aws";


// ** AUTH RELATED ENDPOINTS **
export const AuthEndPoints = {
  login: vendorPrefix + "/login",
  verifyUser: userPrefix + "/get-user",
  logout: authPrefix + "/" + userPrefix + "/logout",
  editUser: managementPrefix + "/edit-vendor",
};

export const resetPasswordEndPoints = {
  verifyEmail: vendorPrefix + "/forgotpassword",
  verifyOtp: vendorPrefix + "/validateotp",
  resetPassword: vendorPrefix + "/resetPassword",
};

export const versionEndPoint = {
  checkVersion: versionPrefix + "/get-version-dashboard",
};
export const homeEndPoints = {
  complaintCount: vendorPrefix + "/get-complaint-status-count",
  vendorComplaintsCount: vendorPrefix + "/vendor-complaint-counts",
  vendorGraph: vendorPrefix + "/get-complaint-count-monthly",
  getComplaints: managementPrefix + "/get-all-complaints",
  getNotification: managementPrefix + "/get-notifications",
};

export const complaintEndPoints = {
  getProperty: managementPrefix + "/get-all-properties",
  getFloors: visitorPrefix + "/floors",
  getFlats: visitorPrefix + "/flats",
  getSubCategory: managementPrefix + "/get-subcomplaint-category",
  getSector: managementPrefix + "/get-sector",
  getSubSubCategory: managementPrefix + "/get-sub-sub-category",
};

export const updateProcessEndPoints = {
  updateComplaintLifeCycle: managementPrefix + "/complaint-life-cycle",
  resolveComplaint: managementPrefix + "/resolve-or-close-complaint",
  notifyCustomer: managementPrefix + "/notify-customer",
  cancelComplaint: managementPrefix + "/cancel-life-cycle",
  withdrawDeadline: vendorPrefix + "/withdraw-complaint-deadline-request",
  closeOrDeadlineRequest: vendorPrefix + "/add-complaint-deadline",
  updateSubSubCategory:
    managementPrefix + "/add-deadline-time-sub-sub-category",
};

export const vendorSwitchEndPoints = {
  viewVendorProfile: vendorPrefix + "/view-vendor-profile",
  switchVendorProfile: vendorPrefix + "/switch-vendor-profile",
};
// ** ALL AWSS3 RELATED ROUTES *
export const AwsS3Routes = {
  getPreSignedURL: awsPrefix + "/generate-presigned-url",
};